import '../App.css';
import React from 'react';
import Top from '../components/Top';
import Footer from '../components/Footer';
import RegCompleteAmble from "../components/RegCompleteAmble";


function RegComplete() {

    return (
        <div>
            <Top/>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h1 className="contact_taital">Registration Complete</h1>
                    </div>
                    <RegCompleteAmble/>

                </div>
            </div>
            <Footer/>
        </div>
    )

}

export default RegComplete;
