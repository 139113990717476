import React from "react";

const AppCompleteAmble = ({ title, onAdd, showAdd }) => {

  return (
    <header className='preamble'>
        <hr/>
      <div className="formContents"  style={{fontSize:16}}>Your application has been completed. We will be in touch with the next steps.</div>

    </header>
  );
}


export default AppCompleteAmble
