import '../App.css';
import React from 'react';
import Top from '../components/Top';
import {useState, useEffect, useRef} from 'react'
import Footer from '../components/Footer';
import PreambleLogout from "../components/PreambleLogout";
import {localNetwork} from "../components/Network";
import {useNavigate} from "react-router-dom";
import useToken from "../components/UseToken";


function Logout() {
    //const {token, setToken} = useToken();

    const navigate = useNavigate();

    const [password, setPassword] = useState({});
    const [email, setEmail] = useState({});

    const slidesContainer = useRef(null);
    const clientRef = useRef(null);

    useEffect(() => {}, [])

    // Add
    const pushResponse = async (formResponse) => {
        let me = "ivansancehz"
        console.log("Token was set as: " + me )
        localStorage.setItem('token', JSON.stringify(me));
        navigate("/");
        let timestamp = Math.floor(Date.now() /1000) + (60*60*24);

    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let mp = new Map();
        mp.set("email", email);
        mp.set("password", password);
        pushResponse(mp);
        //move to another page
    }


    return (
        <div>
            <Top/>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h1 className="contact_taital">Logout</h1>
                    </div>
                    <PreambleLogout/>

                </div>

            </div>
            <Footer/>
        </div>
    )

}

export default Logout;
