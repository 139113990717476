import { Link } from 'react-router-dom'
import React from "react";

const Footer = () => {
  return (
    <footer style={{marginTop:150, marginBottom:0, position:"fixed", bottom:0, left:0, right:0}}>
        <div className="footer_section layout_padding">
            <div className="container">
                <div className="footer_main" style={{textAlign:"center", alignContent:"center"}}>
                    <div className="location_text" style={{textAlign: "center", alignContent: "center"}}>
                        <ul>
                            <li>
                                <a href="https://smoreworks.com">
                                    <span style={{textAlign: "center", visibility:"hidden"}}>Copyright 2024© </span>
                                </a>
                            </li>

                        </ul>
                        <ul>
                            <li>
                                <a href="https://smoreworks.com">
                                    <span style={{textAlign: "center", visibility:"hidden"}}>Copyright ©2024 SMOREWORKS</span>
                                </a>
                            </li>

                        </ul>
                        <ul>
                            <li>
                                <a href="https://smoreworks.com">
                                    <span style={{textAlign: "center"}}>Copyright ©2024 SMOREWORKS</span>
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>


    </footer>
  )
}

export default Footer
