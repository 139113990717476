import React from "react";
import Top from "../components/Top";
import Carousels from "../components/Carousels";
import Footer from "../components/Footer";
import leftimage from "../images/global.jpg"
import middleimage from "../images/deeptech.jpg"
import rightimage from "../images/diversity.jpg"

function Home() {

    return (
        <div >
            <Top/>
            <Carousels />
            <div className="container" style={{maxWidth:1500, height:1700}}>

                <div className="contact_section_1" style={{paddingTop: 10}}>
                    <div className="row">
                        <div className="col-md-6" style={{maxWidth:500}}>
                            <div className="mail_section_1" style={{width: 450}}>
                                <img src={leftimage} style={{marginTop: 25}}/>
                                <div style={{
                                    color: "#ffffff",
                                    backgroundColor: "#929292",
                                    paddingLeft: 25,
                                    paddingRight: 25,
                                    paddingBottom: 20,
                                    fontSize: 20
                                }}>
                                    <div
                                        style={{fontSize: 32, textAlign: "center", fontWeight: "bold"}}>Financial
                                        Injection and Risk Mitigation
                                    </div>
                                    Significant Capital Infusion: Government funding provides a substantial financial
                                    boost, enabling your startup to scale operations rapidly. This capital can be used
                                    to hire top talent, accelerate product development, expand market reach, and invest
                                    in research and development.
                                    Reduced Reliance on Traditional Financing: By securing government capital, you can
                                    lessen your dependence on venture capital and private equity, which often involve
                                    stringent terms and dilution of ownership. This allows you to maintain greater
                                    control over your company's strategic direction.
                                    <br/> <br/></div>
                            </div>
                        </div>


                        <div className="col-md-6" style={{maxWidth: 500}}>
                            <div className="mail_section_1" style={{width: 450}}>
                                <img src={middleimage} style={{marginTop: 25}}/>
                                <div style={{
                                    color: "#ffffff",
                                    backgroundColor: "#929292",
                                    paddingLeft: 25,
                                    paddingRight: 25,
                                    fontSize: 20,
                                    paddingBottom: 20
                                }}>
                                    <div style={{fontSize: 32, textAlign: "center", fontWeight: "bold"}}>Enhanced Credibility and Market Access
                                    </div>
                                    Increased Credibility and Brand Reputation: Government backing significantly enhances your startup's credibility and brand reputation. Being associated with government initiatives signals to potential investors, customers, and partners that your business is innovative, promising, and aligned with societal goals.
                                    Accelerated Market Entry: Government support can expedite your market entry and expansion. This can be achieved through various means, such as preferential treatment in government procurement processes, access to government networks and resources, and reduced regulatory hurdles.
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6" style={{maxWidth: 500}}>
                            <div className="mail_section_1" style={{width: 450}}>
                                <img src={rightimage} style={{marginTop: 25}}/>
                                <div style={{
                                    color: "#ffffff",
                                    backgroundColor: "#929292",
                                    paddingLeft: 25,
                                    paddingRight: 25,
                                    fontSize: 20,
                                    paddingBottom: 20
                                }}>
                                    <div style={{fontSize: 32, textAlign: "center", fontWeight: "bold"}}>Alignment with Priorities and Policy Support
                                    </div>
                                    Policy Alignment and Regulatory Support: Government funding often comes with the advantage of aligning your business with national priorities and policy goals. This can lead to additional benefits such as streamlined regulatory approvals, tax incentives, and preferential treatment in government procurement processes.
                                    Long-Term Sustainability: By aligning your business with national priorities, you can ensure long-term sustainability and growth. Government support can provide a stable foundation for your business, helping you weather economic downturns and adapt to changing market conditions.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>

        </div>
    );

}

export default Home;