import React from "react";
import Header from "../components/Header";
import Top from "../components/Top";
import Footer from "../components/Footer";
import kickoff from "../images/kickoff.jpg";
import tokyosession from "../images/tokyosession.jpg";
import boston from "../images/boston.jpg";
import bostonsession from "../images/bostonsession.jpg";
import footerimage from "../images/scheduletime.jpg";
import Carousels from "../components/CarouselsAbout";

function About() {

    return (
        <div>
            <Top/>
            <Header/>

            <div className="container" style={{maxWidth: 2000, height: 1320, paddingRight: 0}}>
                <div className="contact_section_1"
                     style={{paddingTop: 20, marginLeft: "10%", paddingRight: 0, paddingBottom: 10}}>
                    <div style={{height: 300, width: 1800, backgroundColor: "#929292"}}>
                        <div style={{float: "left"}}>
                            <img src={kickoff} style={{marginLeft: 0, float: "left", height: 300}}/>
                        </div>
                        <div style={{
                            color: "#ffffff",
                            paddingLeft: 35,
                            paddingRight: 200,
                            paddingBottom: 20,
                            width: 1000,
                            fontSize: 20,
                            float: "left"
                        }}>
                            <div
                                style={{fontSize: 22, textAlign: "center", fontWeight: "bold"}}>Open the application for
                                Japanese deep-tech startups, gateway for the US top ecosystem
                            </div>
                            <br/>
                            Select approximately 20 start-ups from the applicant pool for the coming Tokyo session
                            through the 1st judge.
                            <br/> <br/>
                        </div>
                    </div>
                </div>
                <div className="contact_section_1"
                     style={{paddingTop: 20, marginLeft: "10%", paddingRight: 0, paddingBottom: 10}}>
                    <div style={{height: 300, width: 1800, backgroundColor: "#929292"}}>
                        <div style={{float: "left"}}>
                            <img src={tokyosession} style={{marginLeft: 0, float: "left", height: 300}}/>
                        </div>
                        <div style={{
                            color: "#ffffff",
                            paddingLeft: 35,
                            paddingRight: 200,
                            paddingBottom: 20,
                            width: 1000,
                            fontSize: 20,
                            float: "left"
                        }}>
                            <div
                                style={{fontSize: 22, textAlign: "center", fontWeight: "bold"}}>Provide Needs Assessment
                                and sessions for each start-up
                            </div>
                            <br/>
                            Identify the needs for each start-up and provide sessions and advice for product
                            development, product market-fit as well as growth depending on their business phase.
                            <br/> <br/></div>
                    </div>
                </div>
                <div className="contact_section_1"
                     style={{paddingTop: 20, marginLeft: "10%", paddingRight: 0, paddingBottom: 10}}>
                    <div style={{height: 300, width: 1800, backgroundColor: "#929292"}}>
                        <div style={{float: "left"}}>
                            <img src={boston} style={{marginLeft: 0, float: "left", height: 300}}/>
                        </div>
                        <div style={{
                            color: "#ffffff",
                            paddingLeft: 35,
                            paddingRight: 200,
                            paddingBottom: 20,
                            width: 1000,
                            fontSize: 20,
                            float: "left"
                        }}>
                            <div
                                style={{fontSize: 22, textAlign: "center", fontWeight: "bold"}}>Provide Needs
                                Assessment, and sessions for each start-up
                            </div>
                            <br/>
                            Identify the needs for each start-up and provide sessions and advices for product
                            development, product-market fit, and growth, etc depending on their business phase.
                            <br/> <br/></div>
                    </div>
                </div>
                <div className="contact_section_1"
                     style={{paddingTop: 20, marginLeft: "10%", paddingRight: 0, paddingBottom: 10}}>
                    <div style={{height: 300, width: 1800, backgroundColor: "#929292"}}>
                        <div style={{float: "left"}}>
                            <img src={bostonsession} style={{marginLeft: 0, float: "left", height: 300}}/>
                        </div>
                        <div style={{
                            color: "#ffffff",
                            paddingLeft: 35,
                            paddingRight: 200,
                            paddingBottom: 20,
                            width: 1000,
                            fontSize: 20,
                            float: "left"
                        }}>
                            <div
                                style={{fontSize: 22, textAlign: "center", fontWeight: "bold"}}>Invite selected 10
                                start-ups to Boston session
                            </div>
                            <br/>
                            Provide full Boston session curriculum as well as networking, panel sessions, 1:1 mentoring,
                            industry specific sessions, private pitch training etc. to enable start-ups to be prepared
                            for the global market
                            <br/> <br/></div>
                    </div>
                </div>
            </div>
            <div>
                <img src={footerimage} style={{width: "110%", paddingBottom: 40, paddingLeft: "-20"}}/>
            </div>
            <Footer/>

        </div>
    )
}


export default About
