import React from "react";

const PreambleLogin = ({  }) => {

    const invalidator = ()=>{

    }

  return (
      <header className='preamble'>
          <hr/>
          <div className="formContents" style={{fontSize: 16}} >You have been logged out.
          </div>

      </header>
  );
}


export default PreambleLogin
