import {FaBars} from "react-icons/fa";
import {NavLink as Link} from "react-router-dom";
import styled from "styled-components";
import aboutimage from "../images/aboutTop.jpg";
//import bgimage from "../images/testbg.jpg";

export const Nav = styled.nav`
    background: #fafafa;
    height: 85px;
    display: flex;
    justify-content: space-between;
    padding: 0.2rem calc((100vw - 1000px) / 2);
    z-padding: 12;
`;

export const NavLink = styled(Link)`
    color: #808080;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    &.active {
        color: #4d4dff;
    }
`;

export const Bars = styled(FaBars)`
    display: none;
    color: #808080;
    @media screen and (max-width: 768px){
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%,75%);
        font-size: 1.8rem;
        cursor: pointer;
    }
`;

export const NavMenu = styled.div`
    display: flex;
    align-items: center;
    margin-right: -24px;
   
    @media screen and (max-width: 768px){ display: none;}
     
`;

export const MainBox = styled.div`
    display: block;
    width: 100%;
    height: auto;
    padding-top: 5rem;
    padding-bottom: 30rem;
    margin-right: -24px;
    text-align: left;
    background-color: #666666;
    background-size:100%;
    background-image:  url(${aboutimage}) 
`;