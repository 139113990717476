import React from "react";
import Header from "./Header";
import CarouselRow from "./CarouselRow";

const Carousels = () => {
    return (
        <div className="banner_section layout_padding">
            <div className="container">
                <div id="main_slider" className="carousel slide" data-ride="carousel" >
                    <div className="carousel-inner" style={{marginLeft:-200, width:1500}}>
                        <div className="carousel-item active">
                            <CarouselRow title={"Worldwide Network"} text={"Secure capital to further business objectives, accelerate progress towards milestones for your products and take advantage of well thought out advancement opportunities"}/>
                        </div>
                        <div className="carousel-item">
                            <CarouselRow title={"Professional Review"} text={"Fund research and expenses and leverage product positions in international markets to realize potential for expansion"}/>
                        </div>
                        <div className="carousel-item">
                            <CarouselRow title={"International Support"} text={"Leverage ideas from like-minded think tanks to better position yourself and your product in the worldwide market space."}/>
                        </div>
                    </div>
                    <a className="carousel-control-prev" href="#main_slider" role="button" data-slide="prev">
                        <i className="fa fa-angle-left"></i>
                    </a>
                    <a className="carousel-control-next" href="#main_slider" role="button" data-slide="next">
                        <i className="fa fa-angle-right"></i>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Carousels;
