import {FaBars} from "react-icons/fa";
import {NavLink as Link} from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
    background: #fafafa;
    height: 85px;
    display: flex;
    justify-content: space-between;
    padding: 0.2rem calc((100vw - 1000px) / 2);
    z-padding: 12;
`;

export const NavLink = styled(Link)`
    color: #FFFFFF;
    font-size: 18px;
    margin: 0px 30px 0px 0px;
`;

export const RegisterLink = styled(Link)`
    color: #FFFFFF;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    float: right;
    background-color: #002060;
    border-radius: 10px;
    font-size: 18px;

`;
export const ApplyLink = styled(Link)`
    color: #FFFFFF;
    width: auto;
    float: right;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0px 30px 0px 0px;
    background-color: #921eff;
    border-radius: 10px;
    font-size: 18px;

`;

export const LoginLink = styled(Link)`
    color: #FFFFFF;
    width: 300px;
    float: right;
    padding: 10px;
    text-align: center;
    margin: 0px 190px 50px 50px;
    background-color: #353535;
    border-radius: 10px;
    font-size: 18px;

`;

export const NavLinks = styled(Link)`
    color: #575757;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    &.active {
        color: #4d4dff;
    }
`;
export const HeaderLink = styled(Link)`
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    &.active {
        color: #4d4dff;
    }
`;
export const Bars = styled(FaBars)`
    display: none;
    color: #808080;
    @media screen and (max-width: 768px){
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%,75%);
        font-size: 1.8rem;
        cursor: pointer;
    }
`;

export const NavMenu = styled.div`
    display: flex;
    align-items: center;
    margin-left: 30%;
    text-align: right;
   
    @media screen and (max-width: 1068px){ display: none;}
     
`;