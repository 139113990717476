import '../App.css';
import React from 'react';
import Top from '../components/Top';
import Preamble from '../components/Preamble';
import {useState, useEffect, useRef} from 'react'
import Footer from '../components/Footer';
import {localNetwork} from "../components/Network";
import {useForm} from 'react-hook-form';
import {useNavigate} from "react-router-dom";


function AppAlt() {
    const {register, handleSubmit, formState:  {errors}} = useForm();
    let me = JSON.parse(localStorage.getItem('token'));
    const navigate = useNavigate();

    const [startupName, setStartupName] = useState({});
    const [painPoint, setPainPoint] = useState({});
    const [solution, setSolution] = useState({});
    const [impact, setImpact] = useState({});
    const [market, setMarket] = useState({});
    const [validation, setValidation] = useState({});
    const [valueProposition, setValueProposition] = useState({});
    const [sales, setSales] = useState({});
    const [competitors, setCompetitors] = useState({});
    const [productCompliments, setProductCompliments] = useState({});
    const [primaryAdvantages, setPrimaryAdvantages] = useState({});
    const [drivers, setDrivers] = useState({});
    const [ip, setIP] = useState({});
    const [regulation, setRegulation] = useState({});
    const [teamSummary, setTeamSummary] = useState({});
    const [investors, setInvestors] = useState({});
    const [email, setEmail] = useState({});
    const [phone, setPhone] = useState({});
    const slidesContainer = useRef(null);
    const slide = document.querySelector(".slide");
    const clientRef = useRef(null);

    useEffect(() => {}, [])
    let meme = JSON.parse(localStorage.getItem('token'));

    // Add
    const pushResponse = async (formResponse) => {
        let jsonbody = JSON.stringify(Object.fromEntries(formResponse));
        console.log("SUBMITTING")
        console.log(jsonbody)
        fetch(localNetwork+'/application/', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-type': 'application/json'
            },
            body: jsonbody

        }).then(response => {
            response.json().then(parsedVal => {
                console.log(response.status);
                console.log(parsedVal.error);

                if(response.status === 400 ){
                    if(parsedVal.error.includes("login")){
                        navigate("/login")
                    } else {
                        alert(parsedVal.error);
                    }
                }
                if(response.status === 200){
                    //localStorage.setItem('token',JSON.stringify(parsedVal));
                   // console.debug("The token saved is here: " + parsedVal)
                    navigate("/thankyou")
                }
            })
        })
    }

    const onSubmit = (event) => {
        try {
            if (meme === null
                || meme === undefined
                || meme.head === null
                || meme.head === undefined) {
                navigate("/register");
            }
            // event.preventDefault();
            //alert(addressS + furiganaName);
            let mp = new Map();
            mp.set("startupName", startupName);
            mp.set("painPoint", painPoint);
            mp.set("solution", solution);
            mp.set("impact", impact);
            mp.set("market", market);
            mp.set("validation", validation);
            mp.set("valueProposition", valueProposition);
            mp.set("sales", sales);
            mp.set("competitors", competitors);
            mp.set("productCompliments", productCompliments);
            mp.set("primaryAdvantages", primaryAdvantages);
            mp.set("drivers", drivers);
            mp.set("ips", ip);
            mp.set("regulation", regulation);
            mp.set("teamSummary", teamSummary);
            mp.set("investors", investors);
            mp.set("email", meme.head);
            mp.set("phone", "basephone");
            mp.set("userid", meme.session);
            pushResponse(mp);
        }catch(leexception){
            console.log("Error, user not logged in")
            navigate("/register")
        }
        //move to another page
    }

    return (
        <div>
            <Top/>
            <div className="container" style={{marginBottom: 1150, paddingBottom: 1150}}>
                <div className="row">
                    <div className="col-sm-12">
                        <h1 className="contact_taital">Application</h1>
                    </div>
                    <Preamble/>

                </div>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="contact_section_2">

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mail_section_1">
                                    <ul className="slides-containers" id="slides-containers" ref={slidesContainer}>
                                        <li className="slides" ref={clientRef}>
                                            <label className="form_categorytable mail_text">Startup Name:
                                                <textarea name="startup_name" rows="1" cols="30" className="mail_text"  {...register('startupname', {required: true})}
                                                          onChange={(e) => setStartupName((e.target.value))}/></label><br/>
                                            <div>{errors.startupname &&
                                                <span style={{color: "red"}}> The Name of the Startup is required. </span>}</div>

                                            <br/><br/>
                                            <label className="form_categorytable mail_text">Pain Points:
                                                <div>Please describe what problems (customer pain points) are you are
                                                    trying to solve</div>
                                                <textarea rows="10" cols="60" name="pain_point" className="mail_text"  {...register('painpoint', {required: true})}
                                                          onChange={(e) => setPainPoint((e.target.value))}/></label>
                                            <div>{errors.painpoint &&
                                                <span style={{color: "red"}}> Please describe the pain-points that you are attempting to solve. </span>}</div>
                                            <br/><br/>

                                            <label className="form_categorytable mail_text">Solution:
                                                <div>What is your solution? What is innovative about your solution?
                                                    Technology, Business model, etc?</div>
                                                <textarea rows="10" cols="60" name="solution" className="mail_text"  {...register('solution', {required: true})}
                                                          onChange={(e) => setSolution((e.target.value))}/></label><div>{errors.solution &&
                                            <span style={{color: "red"}}> Please enter a described solution. </span>}</div>
                                            <br/>
                                        </li>
                                        <li className="slides">
                                            <label className="form_categorytable mail_text">Impact:
                                                <div>Define the 1 and 5 year impact that you hope to accomplish. Use
                                                    whatever metrics are most appropriate for you (eg revenue, profit,
                                                    jobs, societal benefits).
                                                </div>
                                                <textarea rows="10" cols="60" name="impact" className="mail_text"  {...register('impact', {required: true})}
                                                          onChange={(e) => setImpact((e.target.value))}/></label><div>{errors.impact &&
                                            <span style={{color: "red"}}> Please enter the impact of the solution. </span>}</div>
                                            <br/>

                                            <label className="form_categorytable mail_text">Market:
                                                <div>Describe the market that the product or solution is expected to thrive in and how the market may change because of the solution</div>
                                                <textarea rows="10" cols="60" name="market"
                                                          className="mail_text"  {...register('market', {required: true})}
                                                          onChange={(e) => setMarket((e.target.value))}/></label>
                                            <div>{errors.market &&
                                                <span style={{color: "red"}}> Please describe the market that your solution is targeting. </span>}</div>
                                            <br/>
                                        </li>
                                        <li className="slides">
                                        <label className="form_categorytable mail_text">Validation:
                                                <div>How would you define your potential market and what is the
                                                    addressible market size? Use metrics that are most appropriate for
                                                    you (e.g. revenue, people, devices)
                                                </div>
                                                <textarea rows="10" cols="60" name="validation" className="mail_text"  {...register('validation', {required: true})}
                                                          onChange={(e) => setValidation((e.target.value))}/></label><div>{errors.validation &&
                                            <span style={{color: "red"}}> Please describe what and how you are validating solution success. </span>}</div>
                                            <br/>

                                            <label className="form_categorytable mail_text">Value Proposition:
                                                <textarea rows="10" cols="60" name="value_proposition"
                                                          className="mail_text" {...register('valueproposition', {required: true})}
                                                          onChange={(e) => setValueProposition((e.target.value))}/></label><div>{errors.valueproposition &&
                                            <span style={{color: "red"}}> Please describe the value proposition of your solution. </span>}</div>
                                            <br/>
                                        </li>
                                        <li className="slides">
                                        <label className="form_categorytable mail_text">Sales:
                                                <textarea rows="10" cols="60" name="sales" className="mail_text"  {...register('sales', {required: true})}
                                                          onChange={(e) => setSales((e.target.value))}/></label><div>{errors.sales &&
                                            <span style={{color: "red"}}> Please describe the sales on your product. </span>}</div>
                                            <br/>

                                            <label className="form_categorytable mail_text">Competitors:
                                                <div>Which organizations compete with your value offering now and who
                                                    might do so in the future?
                                                </div>
                                                <textarea rows="10" cols="60" name="competitors" className="mail_text"  {...register('competitors', {required: true})}
                                                          onChange={(e) => setCompetitors((e.target.value))}/></label><div>{errors.competitors &&
                                            <span style={{color: "red"}}> Please describe the competitors in the marketplace for this product/idea. </span>}</div>
                                            <br/>
                                        </li>
                                        <li className="slides">
                                            <label className="form_categorytable mail_text">Product Compliments:
                                                <div>What are your primary advantages relative to existing or potential
                                                    competitors? ie.e Why will you win?
                                                </div>
                                                <textarea rows="10" cols="60" name="product_compliments"
                                                          className="mail_text"  {...register('compliments', {required: true})}
                                                          onChange={(e) => setProductCompliments((e.target.value))}/></label><div>{errors.compliments &&
                                            <span style={{color: "red"}}> Please describe the potential competitors </span>}</div>
                                            <br/>

                                            <label className="form_categorytable mail_text">Primary Advantages:
                                                <textarea rows="10" cols="60" name="primary_advantages"
                                                          className="mail_text"  {...register('advantages', {required: true})}
                                                          onChange={(e) => setPrimaryAdvantages((e.target.value))}/></label><div>{errors.advantages &&
                                            <span style={{color: "red"}}> Please describe the advantages of your product on the market and/or to consumers. </span>}</div>
                                            <br/>
                                        </li>
                                        <li className="slides">
                                            <label className="form_categorytable mail_text">Drivers:
                                                <textarea rows="10" cols="60" name="drivers" className="mail_text"  {...register('drivers', {required: true})}
                                                          onChange={(e) => setDrivers((e.target.value))}/></label><div>{errors.drivers &&
                                            <span style={{color: "red"}}> Please describe the primary drivers for the development and design of your solution. </span>}</div>
                                            <br/>

                                            <label className="form_categorytable mail_text">Intellectual Property:
                                                <div>What IP  requirements exist for you business or within
                                                    your industry? Who owns your IP currently? Do you foresee IP as a
                                                    competitive advantage?
                                                </div>
                                                <textarea rows="10" cols="60" name="ip" className="mail_text"  {...register('intel', {required: true})}
                                                          onChange={(e) => setIP((e.target.value))}/></label><div>{errors.intel &&
                                            <span style={{color: "red"}}> Please describe the ownership of the IP being used and challenges/advantages of it. </span>}</div>
                                            <br/>
                                        </li>
                                        <li className="slides">
                                            <label className="form_categorytable mail_text">Regulation:
                                                <div>What regulatory requirements exist for you business or within
                                                    your industry?
                                                </div>
                                                <textarea rows="10" cols="60" name="regulation" className="mail_text" {...register('regulation', {required: true})}
                                                          onChange={(e) => setRegulation((e.target.value))}/></label><div>{errors.regulation &&
                                            <span style={{color: "red"}}> Please describe the regulations surrounding your product/idea. </span>}</div>
                                            <br/>

                                            <label className="form_categorytable mail_text">Team Summary:
                                                <div>Please share some background information on your team members, and
                                                    tell us what makes your team special
                                                </div>
                                                <textarea rows="10" cols="60" name="team_summary" className="mail_text" {...register('summary', {required: true})}
                                                          onChange={(e) => setTeamSummary((e.target.value))}/></label><div>{errors.summary &&
                                            <span style={{color: "red"}}> Please tell us about your team. </span>}</div>
                                            <br/>
                                        </li>
                                        <li className="slides" style={{marginBottom: 140}}>
                                            <label className="form_categorytable mail_text">Investors:
                                                <textarea rows="10" cols="60" name="investors" className="mail_text"  {...register('investors', {required: true})}
                                                          onChange={(e) => setInvestors((e.target.value))}/></label><div>{errors.investors &&
                                            <span style={{color: "red"}}> Please tell us about your investors. </span>}</div>
                                            <br/>

                                        </li>
                                        <li className="slides" style={{marginTop: 140}}>
                                        </li>
                                    </ul>

                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="map_main">
                                    <div className="map-responsive">
                                        <div>To provide investors with a comprehensive understanding of your business
                                            and
                                            its potential, we kindly request that you complete the attached
                                            questionnaire as
                                            thoroughly as possible. By sharing detailed information about your product
                                            or
                                            service, such as its unique value proposition, target market, and
                                            competitive
                                            advantage, you can help investors assess its market viability and potential
                                            for
                                            growth. For instance, if you can quantify your product's impact on customer
                                            acquisition costs or revenue, investors can better evaluate its financial
                                            potential. Additionally, providing insights into your business model,
                                            including
                                            your revenue streams, cost structure, and scalability, will enable investors
                                            to
                                            gauge its sustainability and profitability. For example, a clear explanation
                                            of
                                            your unit economics can help investors understand the profitability of each
                                            customer acquisition. By sharing information about the challenges you face,
                                            such
                                            as fundraising, talent acquisition, or regulatory hurdles, you can
                                            demonstrate
                                            your awareness of potential risks and your ability to mitigate them. For
                                            instance, a well-articulated plan for addressing supply chain disruptions
                                            can
                                            reassure investors about the resilience of your business
                                        </div>
                                        <div><input className="send_bt" type="submit"/></div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div></form>
            </div>
            <div style={{margin: 150}}>
                <br/><br/><br/><br/><br/>
            </div>
            <Footer/>
        </div>
    )

}

export default AppAlt;
