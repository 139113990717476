import '../App.css';
import React from 'react';
import Top from '../components/Top';
import Preamble from '../components/Preamble';
import {useState, useEffect} from 'react'
import Footer from '../components/Footer';
import {localNetwork} from "../components/Network";
import {LoginLink} from "../components/NavbarElements"
import {useNavigate} from 'react-router-dom'
import hirogate from "../images/hirogate.jpg"
import {useForm} from 'react-hook-form';

function Register() {
    const {register, handleSubmit, formState:  {errors}} = useForm();
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    const [firstName, setFirstName] = useState({});
    const [lastName, setLastName] = useState({});
    const [password, setPassword] = useState({});
    const [password2, setPassword2] = useState({});
    const [email, setEmail] = useState({});

    useEffect(() => {}, [])

    const navigate = useNavigate();

    // Add
    const pushResponse = async (formResponse) => {
        let jsonbody = JSON.stringify(Object.fromEntries(formResponse));
        console.log(jsonbody)
        fetch(localNetwork+'/registration/', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-type': 'application/json'
            },
            body: jsonbody

        }).then(response => {
            response.json().then(parsedVal => {
                console.log(response.status);
                console.log(parsedVal.error);

                if(response.status === 400 && parsedVal.error.includes("email")){
                    alert("Please use a different email address");
                } else
                if(response.status === 400 && parsedVal.error.includes("Strength")){
                    alert("Passwords must be at least 7 characters long, 1 must be a number and a symbol(!,*,@, etc)");
                } else
                if(response.status === 400 && parsedVal.error.includes("password")){
                    alert("Please use a different password");
                } else
                if(response.status === 400 ){
                    alert(parsedVal.error);
                }
                if(response.status === 200){
                    navigate("/regcomplete")
                }
            })
        })
    }

    const onSubmit = (event) => {
        let mp = new Map();
        mp.set("firstname", firstName);
        mp.set("lastname", lastName);
        mp.set("email", email);
        mp.set("password", password);

        if(password !== password2){
            alert("Please Ensure Passwords match");
        } else if(email===password) {
            alert("Invalid Password")
        } else {
            pushResponse(mp);
        }

    }


    return (
        <div>
            <Top/>
            <div className="container" style={{maxWidth: 1400, height: 1700}}>
                <div className="row">
                    <div className="col-sm-12">
                        <h1 className="contact_taital">Applicant Registration</h1>
                    </div>
                    <Preamble/>

                </div>
                <div className="contact_section_2">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="map_main">
                                <div className="map-responsive">
                                    <div>
                                    </div>
                                    <div></div>
                                    <div>
                                        <LoginLink to="../login" activeStyle> Already Registered? Login
                                            Here </LoginLink>
                                    </div>
                                    <div>
                                        <img src={hirogate} style={{padding: 5, marginBottom: 5}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mail_section_1">
                                <ul className="slides-containers" id="slides-containers">
                                    <li className="slides">
                                        <label className="form_categorytable mail_text" style={{marginBottom: 40}}>Registration:
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <input type="text" className="mail_text" placeholder="First Name"
                                                       name="FirstName" {...register('firstname', {required: true})}
                                                       onChange={(e) => setFirstName((e.target.value))}/>
                                                <div>{errors.firstname && <span style={{color: "red"}}> First Name is required. </span>}</div>
                                                <input type="text" className="mail_text" placeholder="Last Name"
                                                       name="Lastname" {...register('two', {required: true})}
                                                       onChange={(e) => setLastName((e.target.value))}/>
                                                <div>{errors.two && <span style={{color: "red"}}> Last Name is required. </span>}</div>
                                                <input type="text" className="mail_text" placeholder="Email"
                                                       name="Email" {...register('email', {required: true, pattern:  emailRegex})}
                                                       onChange={(e) => setEmail((e.target.value))}/>
                                                <div>{errors.email && <span style={{color: "red"}}> A valid email is required. </span>}</div>
                                                <input type="password" className="mail_text" placeholder="Password"
                                                       name="Password" {...register('password', {required: true})}
                                                       onChange={(e) => setPassword((e.target.value))}/>
                                                <div>{errors.password && <span style={{color: "red"}}> Password is required. </span>}</div>
                                                <input type="password" className="mail_text"
                                                       placeholder="Confirm Password"
                                                       name="PasswordConfirm" {...register('passwordconfirm', {required: true})}
                                                       onChange={(e) => setPassword2((e.target.value))}/>
                                                <div>{errors.passwordconfirm && <span style={{color: "red"}}> Password Confirmation is Required </span>}</div>

                                                <div style={{paddingLeft: 50, paddingTop: 20, marginTop: 0}}><br/><br/>
                                                    <input className="send_bt" type="Submit" value="Register"
                                                           style={{marginTop: 0, marginLeft: 175, marginBlock: 50}}/>
                                                </div>

                                            </form>
                                            <br/><br/>

                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <br/>
                <br/>
                <br/>
            </div>
            <br/>
            <br/>
            <br/>
            <Footer/>
        </div>
    )

}

export default Register;
