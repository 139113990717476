import React from "react";
import Top from "../components/Top";
import Footer from "../components/Footer";
import AppCompleteAmble from "../components/AppCompleteAmble";
function Thankyou() {

    return (
        <div>
            <Top/>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h1 className="contact_taital">Application Complete</h1>
                    </div>
                    <AppCompleteAmble/>

                </div>
            </div>
            <Footer/>
        </div>
    )
}


export default Thankyou
