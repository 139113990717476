import '../App.css';
import React from 'react';
import Top from '../components/Top';
import {useState, useEffect, useRef} from 'react'
import Footer from '../components/Footer';
import PreambleLogin from "../components/PreambleLogin";
import {localNetwork} from "../components/Network";
import {useNavigate} from "react-router-dom";
import {useForm} from 'react-hook-form';


function Login() {
    const {register, handleSubmit, formState:  {errors}} =
        useForm();
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const navigate = useNavigate();

    const [password, setPassword] = useState({});
    const [email, setEmail] = useState({});

    const clientRef = useRef(null);

    useEffect(() => {}, [])

    // Add
    const pushResponse = async (formResponse) => {
        let jsonbody = JSON.stringify(Object.fromEntries(formResponse));
        fetch(localNetwork + '/userlogin/', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-type': 'application/json'
            },
            body: jsonbody

        }).then(response => {
            response.json().then(parsedVal => {
                console.log(response.status);
                console.log(parsedVal.error);

                if(response.status === 400 ){
                    alert("The Username or Password is incorrect");
                }
                if(response.status === 200){
                    localStorage.setItem('token',JSON.stringify(parsedVal));
                   // console.log("The token saved is here: " + parsedVal.head)
                  //  let meme = JSON.parse(localStorage.getItem('token'));
                   // console.log("The user saved is here: " + meme.head)

                    navigate("/")
                }
            })
        })
    }

    const onSubmit = (event) => {
        let mp = new Map();
        mp.set("email", email);
        mp.set("password", password);
        pushResponse(mp);
        //move to another page
    }


    return (
        <div>
            <Top/>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h1 className="contact_taital">Login</h1>
                    </div>
                    <PreambleLogin/>

                </div>
                <div className="contact_section_2">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mail_section_1">
                                <ul className="slides-containers" id="slides-containers">
                                    <li className="slides" ref={clientRef}>

                                        <label className="form_categorytable mail_text" style={{marginBottom:40}}>Login:
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <input type="text" className="mail_text" placeholder="Email"
                                                       name="email" {...register('email', {required: true, pattern: emailRegex})}
                                                       onChange={(e) => setEmail((e.target.value))}/>
                                                <div>{errors.email && <span style={{color: "red"}}> Valid email is required. </span>}</div>
                                                <input type="password" className="mail_text" placeholder="Password" style={{marginBottom:0}}
                                                       name="password" {...register('password', {required: true})}
                                                       onChange={(e) => setPassword((e.target.value))}/>
                                                <div style={{marginBottom:20}}>{errors.password && <span style={{color: "red"}}> Password is required. </span>}</div>
                                                <div style={{marginTop:20, marginLeft:150}}>
                                                    <input className="send_bt" type="Submit" value="Login" style={{marginBottom:40, marginTop:20}}/>
                                                </div>

                                            </form>
                                            <form>
                                            </form>

                                        </label>

                                        <br/><br/>


                                    </li>


                                </ul>
                                <br/><br/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="map_main">
                                <div className="map-responsive">
                                    <div>Login to access your account and application</div>
                                    <img   src="https://ivansanchez.com/images/constellogin.png" width="600"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )

}

export default Login;
