import {useState} from 'react';

export default function useToken() {
    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        console.debug("TOKEN IS HERE: " + userToken?.token)
        return userToken?.token;
    }
    const[token, setToken] = useState(getToken());
    const saveToken = userToken => {
        localStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken.token);
        console.debug("saving token ");
    };

    return {
        setToken:saveToken,
        token}
}