import React from "react";
import {Nav, NavLink, NavMenu, RegisterLink, ApplyLink} from "./NavbarElements"
import useToken from "./UseToken";
import constitle from "../images/theconstellationtitle.png"
import {useNavigate} from "react-router-dom";

const Top = ({ title, onAdd, showAdd }) => {
    const {token, setToken} = useToken();
    let me = JSON.parse(localStorage.getItem('token'));
    const navigate = useNavigate();

    const logout = () => {
        localStorage.setItem('token',null);
        localStorage.clear();
        navigate("/logout")
    }

  return (
      <div className="header_section" >
          <div className="container-fluid">
              <nav className="navbar navbar-expand-lg navbar-light bg-light">
                  <img src={constitle} width="500px"/>
                  <NavMenu>
                      <div className="collapse navbar-collapse" id="navbarSupportedContent">
                          <ul className="navbar-nav ml-auto">
                              <li className="nav-item active">
                                  <NavLink to="/" activeStyle> Home</NavLink>
                              </li>

                              <li className="nav-item">
                                  <NavLink to="/about" activeStyle> About</NavLink>
                              </li>
                          </ul>
                          {me? (
                              <>
                          <div>
                              <ApplyLink to="/application" activeStyle> Application</ApplyLink>
                          </div>
                              <div>
                              <RegisterLink  to={"/logout"} activeStyle onClick={logout}> Logout</RegisterLink>
                          </div></>):(
                          <div>
                              <RegisterLink to="/register" activeStyle> Login / Register</RegisterLink>
                          </div>)}

                      </div>
                  </NavMenu>
              </nav>

          </div>

      </div>

  )
}


export default Top
