import './App.css';
import React, {useState} from 'react';
import Home from "./pages/home";
import AppAlt from "./pages/application";
import Thankyou from "./pages/thankyou";
import About from "./pages/about";
import RegComplete from "./pages/regcomplete";
import Login from "./pages/login";
import Register from "./pages/register";
import Logout from "./pages/logout";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import useToken from "./components/UseToken";
function App() {
    let me = JSON.parse(localStorage.getItem('token'));
    return(
        <Router>
            <Routes>
                <Route exact path="/" element={<Home />}/>
                <Route  path="/application" element={<AppAlt />}/>
                <Route  path="/thankyou" element={<Thankyou />}/>
                <Route  path="/login" element={<Login />}/>
                <Route  path="/register" element={<Register />}/>
                <Route  path="/about" element={<About />}/>
                <Route  path="/regcomplete" element={<RegComplete />}/>
                <Route  path="/appcomplete" element={<About />}/>
                <Route  path="/logout" element={<Logout />}/>
            </Routes>
        </Router>
    );
}

export default App;
