import React from "react";

const RegCompleteAmble = ({ title, onAdd, showAdd }) => {

  return (
    <header className='preamble'>
        <hr/>
      <div className="formContents"  style={{fontSize:16}}>Registration has been completed. Please login with your username and password</div>

    </header>
  );
}


export default RegCompleteAmble
