import React from "react";

const Preamble = ({ title, onAdd, showAdd }) => {

  return (
    <header className='preamble'>
        <hr/>
      <div className="formContents"  style={{fontSize:18}}>Ready to revitalize your startup? We are ready to help you get the capital and exposure you are looking for.<br/>
          Complete the registration to start sharing your vision and be on the road to the funding needed to bring your innovative ideas to life</div>

    </header>
  );
}


export default Preamble
