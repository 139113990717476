import React from "react";

const CarouselRow = ({title, text, url}) => {
    return (
    <div className="row" style={{width:1200}}>
        <div className="col-sm-12">
            <h1 className="banner_taital" style={{color: "white"}}>{title}</h1>
            <p className="banner_text" style={{color: "white"}}>{text}</p>
            <div className="started_text" style={{display:"none"}}><a href="https://ivansanchez.com">Contact Us</a></div>
        </div>
    </div>)
}

export default CarouselRow;