import React from "react";
import {MainBox} from "./IndexElements";
import aboutimage from "../images/aboutTop.jpg"

const Header = () => {

  return (
      <div>
          <MainBox ></MainBox>
      </div>
  )
}


export default Header
